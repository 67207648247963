<template>
  <div id="contact" class="container-fluid footer bg-black">
    <div class="container">
      <div class="row py-2">
        <div class="col-12 col-lg-4">
          <div class="d-flex flex-column text-light mt-2">
            <span class="fs-3" style="color: var(--primary);">Contact</span>
            <div class="d-flex align-items-center mb-1">
              <img src="../assets/icon/email.png" width="20" height="20" alt="">
              <span class="ms-2"> office@lightideasdc.com</span>
            </div>
            <div class="d-flex align-items-center mb-1">
              <img src="../assets/icon/phone-call.png" width="20" height="20" alt="">
              <a class="ms-2 text-light" href="tel:+959751344373">+959 751344373</a>
            </div>
            <div class="d-flex align-items-center mb-1">
              <img src="../assets/icon/phone-call.png" width="20" height="20" alt="">
              <a class="ms-2 text-light" href="tel:+959798922672">+959 798922672</a>

            </div>
            <div class="d-flex align-items-center mb-1">
              <img src="../assets/icon/facebook.png" width="20" height="20" alt="">
              <a class="ms-2 text-light" href="https://www.facebook.com/lightideasdc">Facebook</a>
            </div>

          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="d-flex flex-column text-light mt-2">
            <span class="fs-3" style="color: var(--primary);">Address</span>
            <div class="align-items-center">
              <img src="../assets/icon/address.png" width="30" height="30" alt="">
              <span class="ms-2">Block 7, Sate Ta Thu Kha Street, Lashio.</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="mt-2">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d918.5772317496768!2d97.76481792919118!3d22.938847199062224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x37329dd951a150ad%3A0x4c36f844e55e30bc!2sLight%20Idea%20Software%20Development%20Company!5e0!3m2!1sen!2smm!4v1659588567235!5m2!1sen!2smm" style="border:0; width: 100%;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>          </div>
        </div>
        <div class="col-12 text-center text-light">
          <span class="text-light" style="font-size: 12px;"> © Copyright Myanmar {{new Date().getFullYear()}}, All right reserved by Light Idea SDC.</span>
        </div>

      </div>

    </div>


  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.footer{
}
a{
  text-decoration: none;
  color: var(--secondary);
}
</style>