<template>
  <div class="container-fluid bg-black">
    <div class="row justify-content-center min-vh-100 align-items-center">
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div class="col-12 col-lg-8 mx-auto ">
        <div class="d-flex flex-column justify-content-between align-items-center justify-content-center">
          <img src="../assets/logo.png" data-aos="zoom-in" width="300" alt="">
          <div class="text-center" data-aos="zoom-in">
            <span class="title-1" data-aos="flip-up" >Light Idea</span><br>
            <span class="title-2" data-aos="fade-up">Software Development Company</span>
          </div>
          <div>
            <a href="#flashmall" data-aos="flip-up" data-aos-easing="ease-in-out"
               class="bounce">
              <img src="../assets/icon/down-arrow.png" width="40" height="40"/>
            </a>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="container-fluid bg-white">


    <div class="container-fluid mt-3 py-3"  style="background-color:#FBFBFD; min-height: 80vh" id="flashmall">
      <div  class="row align-items-center justify-content-center my-auto" >
        <div class="col-12 col-md-8">
          <div class="text-center mx-auto">
            <div class="d-flex my-3 align-items-center justify-content-center">
              <img src="../assets/icon/flashmall-logo.svg" width="60" height="60" alt="">
              <span class="fs-1" style="font-weight: bolder;">Flash Mall</span>
            </div>
            <span class="fs-4" style="color: #5865f6;font-weight: 550;">EPOS System</span>
            <p class="mt-3">
              ဈေးအသက်သာဆုံး EPOS System တစ်ခုကို သင့်လုပ်ငန်းအတွက်အရယူပါ။ ပုံမှန် POS System တစ်ခုထက် အများကြီးသက်သာတဲ့ ဈေးနဲ့ All In One - Smart EPOS System တစ်ခုကို သင်တို့ရဲ့ လုပ်ငန်းတွေအတွက် Light Idea မှ ယူဆောင်လာပါပြီ။
            </p>
            <router-link :to="{name:'FlashMallPage'}" data-aos="fade-up" class="btn text-primary">
              Learn More
            </router-link>
          </div>
        </div>
        <div class="col-12 col-md-5 mx-auto mb-5 pb-5" >
          <div class="img-container">
            <img src="../assets/flash-mockup/flash1.png"  style="border-radius: var(--border-radius);" class="img-one" alt="">
            <img src="../assets/flash-mockup/android.png" style="border-radius: var(--border-radius);" class="img-three" alt="">
            <img src="../assets/flash-mockup/ios.png" style="border-radius: var(--border-radius);" class="img-two" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid" style="background-color: #FBFBFD;min-height: 80vh">
      <div id="flash-tour" class="row d-flex align-items-center justify-content-center mt-3" >
        <div class="col-12 col-md-8 mt-5" >
          <div class="text-center align-items-center justify-content-center" >
            <h1 class="mb-3 fs-1 fw-bold text-black">Flash Tour Myanmar
            </h1>
            <p class="mb-3 fs-5">
              Make your fantastic local trip with our application. Explore famous places, restaurants, hotels and festivals around Myanmar. Everything you need, we will serve as your trip partner. Get it on !!
            </p>
            <div class="d-flex align-items-center justify-content-center">
              <a href="https://apps.apple.com/mm/app/flash-tour-myanmar-premium/id1605932713" class='btn text-primary me-3' ><img
                  src="../assets/icon/app-store.png" width="20" height="20" alt=""> App Store</a>
              <a href="https://play.google.com/store/apps/details?id=com.lightidea.flashtourmyanmarpremium&hl=en_IE&gl=US" class="btn text-primary"><img
                  src="../assets/icon/google-play.png" width="20" alt=""> Play Store</a>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-8 mx-auto mb-5 mt-5">
          <div class="img-container d-lg-flex justify-content-lg-center">
            <img src="../assets/flashtour/home.png" class="ft-1" alt="">
            <img src="../assets/flashtour/hotel.png" class="ft-2" alt="">
            <img src="../assets/flashtour/night.png" class="ft-3" alt="">
            <img src="../assets/flashtour/traditional.png" class="ft-4" alt="">
          </div>
        </div>

      </div>
    </div>

    <div class="container-fluid  mt-3 min-vh-100" id="service" >
      <div class="row ">
        <div class="col-12  ">
          <div class="d-flex flex-column flex-lg-row flex-md-row justify-content-between" >
            <div class="my-card" >
              <div class="service-card-body">
                <span class="title-1"> Our Services </span>
              </div>
            </div>
            <div class="my-card" >
              <div class="service-card-body">
                <img src="https://finbros.co.ke/wp-content/uploads/2021/05/softwareP.jpg" class="w-50 mx-auto" alt="">
                <div data-aos-anchor-placement="top-bottom" >
                  <img src="../assets/icon/web.png" width="100" alt="">

                  <h1 class="my-4" style="font-weight: 500;">Website Development</h1>
                  <p>
                    POS system, E-commerce platform, Delivery system, Inventory Management System and other customize application are available.
                  </p>
                </div>
              </div>

            </div>

          </div>
          <div class="d-flex flex-column flex-lg-row flex-md-row justify-content-between mb-3">
            <div class="my-card" style="background-color:#FBFBFD !important;">
              <div class="service-card-body text-black">
                <img src="https://finbros.co.ke/wp-content/uploads/2021/05/softwareP.jpg" class="w-50 mx-auto" alt="">
                <div data-aos-anchor-placement="top-bottom" >
                  <img src="../assets/icon/apple.png" width="100" alt="">

                  <h1 class="my-4" style="font-weight: 500;">IOS App Development</h1>
                  <p>
                    POS system, E-commerce platform, Delivery system, Inventory Management System and other customize application are available.
                  </p>
                </div>
              </div>

            </div>
            <div class="my-card" style="background-color:#FBFBFD !important;" >
              <div class="service-card-body text-black">
                <img src="https://finbros.co.ke/wp-content/uploads/2021/05/softwareP.jpg" class="w-50 mx-auto" alt="">
                <div data-aos-anchor-placement="top-bottom" >
                  <img src="../assets/icon/android.png" width="100" alt="">
                  <h1 class="my-4" style="font-weight: 500;">Android App Development</h1>
                  <p>
                    POS system, E-commerce platform, Delivery system, Inventory Management System and other customize application are available.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>

  </div>
  <div class="container-fluid" id="about">
    <div  class="row min-vh-100" style="background-color: #000;">
      <div class="col-12 col-lg-6 mx-auto my-auto text-center">
        <h1 class="title-1 mb-5 " style="line-height: 70px;">Light Idea ဆိုတာ ဘယ်လို Company လဲ ?</h1>
        <p class=" mb-5 text-light" style="line-height: 30px;"
        >Mobile နဲ့ Web Application တွေရေးတာပါ။ ကျွန်တော်တို့ light idea မှ နည်းပညာအကူအညီဖြင့် software product များကို ဖန်တီးပြီး လူကြီးမင်းတို့၏ လုပ်ငန်းမှ ပြသနာများစွာကို ကူညီဖြေရှင်း ပေးသွားမှာဖြစ်ပါတယ်။
        </p>
        <router-link :to="{name:'About'}" class="btn btn-outline-light">Read More</router-link>
      </div>
    </div>
  </div>

  <div class="container-fluid"  style="background-color:#FBFBFD"  id="customer">
    <div class="container pb-5">
      <div class="row">
        <div class="col-12">
          <h1 class=" fs-1 fw-bolder text-center my-4" >Our Partners</h1>
          <!--          <vueper-slides
                        :tocuhable="true"
                        :autoplay="true"
                        :arrows="false"
                        class="no-shadow"
                        :visible-slides="5"
                        :slide-ratio="1 / 5"
                        :gap="5"
                        :dragging-distance="60">
                      <vueper-slide v-for="(customer,i) in slides" class="customer" :key="i" :image="customer.image" />
                    </vueper-slides>-->
        </div>
        <div class="col-12 ps-0 col-md-8">
          <div class="row">
            <div class="col-4 col-md-2"  v-for="(slide,index) in slides" :key="index">
              <div class="partner-card " >
                <img :src="slide.image" class="card-img-top" data-aos="zoom-in" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 ps-0 col-md-4">
          <div class="row">
            <div class="col-6 col-lg-6">
              <div class="partner-card " >
                <img src="https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/Mandalay%2Fnsfashionnsf2022-03-12%2Fprofile%2Fnsfashionnsf2022-03-12?alt=media&token=5e034278-9786-4685-b7bf-0e9633bc4576" class="card-img-top" data-aos="zoom-in" alt="">
              </div>
            </div>
            <div class="col-6 col-lg-6">
              <div class="partner-card " style="background-color:#fcac21 !important;" >
                <img src="https://linekrit.com/img/logo.54514e23.png" class="card-img-top" data-aos="zoom-in" alt="">
              </div>
            </div>
            <div class="col-6 col-lg-6">
              <div class="partner-card " >
                <img src="https://shawtal.com/static/media/Logo.685e29d73a6076c6ffb3.png" class="card-img-top" data-aos="zoom-in" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>


</template>

<script>
// import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
  name: "Home",
  // components:{VueperSlides, VueperSlide},
  data() {
    return {
      autoPlaying: true,
      internalAutoPlaying: true,
      slides:[
        {
          name:'Baby Galaxy',
          image:'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/Lashio%2Fbabygalaxybbg2022-05-08%2Fprofile%2Fbabygalaxybbg2022-05-08?alt=media&token=4b18abe4-2157-4ca6-b272-7dd3afb7d58c',
        },
        {
          name:'Beauty Glory',
          image:'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/Lashio%2Fbeautyglorybtg2022-04-27%2Fprofile%2Fbeautyglorybtg2022-04-27?alt=media&token=d983d394-726a-4251-83c2-6f4593c31136',
        },
        {
          name:'Beauty Paradise',
          image:'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/Lashio%2Fbeautyparadisebtp2022-05-12%2Fprofile?alt=media&token=cad09ae1-dbbf-4541-89f2-677d8df9b869',
        },
        {
          name:'Classic Tea House',
          image:'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/Lashio%2Fclassicteahousecth2022-09-27%2Fprofile%2Fclassicteahousecth2022-09-27?alt=media&token=e5b42fbc-2f3e-41dc-8ef7-2b568bbe1015',
        },
        {
          name:'Beauty Glory',
          image:'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/Lashio%2Fbeautyglorybtg2022-04-27%2Fprofile%2Fbeautyglorybtg2022-04-27?alt=media&token=d983d394-726a-4251-83c2-6f4593c31136',
        },
        {
          name:'Fashion 91',
          image:'https://firebasestorage.googleapis.com:443/v0/b/flash-mall-ed921.appspot.com/o/Lashio%2Ffashion91fno2022-07-07%2Fprofile%2Ffashion91fno2022-07-07?alt=media&token=fe33b456-1827-4a4b-a317-5befc978bbda',
        },
        {
          name:'Flower Fashion',
          image:'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/Lashio%2Fflowerfashiononeffn2022-08-11%2Fprofile%2Fflowerfashiononeffn2022-08-11?alt=media&token=d8401531-3a86-415b-9e0b-9fec8ae5e58f',
        },
        {
          name:'Flower Mart',
          image:'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/Lashio%2Fflowermartfmt2022-07-18%2Fprofile%2Fflowermartfmt2022-07-18?alt=media&token=f20ad36e-8513-467c-bdae-61f3e408e129',
        },
        {
          name:'Flower Pharmacy',
          image:'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/Lashio%2Fflowerpharmacyfpy2022-07-04%2Fprofile%2Fflowerpharmacyfpy2022-07-04?alt=media&token=10bae385-5020-47b3-a59d-b05366a9f240',
        },
        {
          name:'Friendship',
          image:'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/Lashio%2Ffriendshiplight%26electronicfle2022-06-16%2Fprofile%2Ffriendshiplight%26electronicfle2022-06-16?alt=media&token=f569805f-842e-4381-b7a2-218bc75619b1',
        },
        {
          name:'Ha Ha Mart',
          image:'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/Lashio%2Fhahamarthhm2022-07-16%2Fprofile%2Fhahamarthhm2022-07-16?alt=media&token=8d7764b5-3932-4fd1-bd32-cce21b881b63',
        },
        {
          name:'Kaung San',
          image:'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/Lashio%2Fkaungsanksn2022-08-02%2Fprofile%2Fkaungsanksn2022-08-02?alt=media&token=d425cf90-96c6-45cb-98ac-c389d031ccb8',
        },
        {
          name:'Kaung Setthit',
          image:'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/Lashio%2Fkaungsetthitkst2022-07-13%2Fprofile%2Fkaungsetthitkst2022-07-13?alt=media&token=92f30ece-85f3-46f2-a1ec-d0d00891b7eb',
        },
        {
          name:'K-Two Electronic',
          image:'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/Lashio%2Fktwokto2022-06-09%2Fprofile%2Fktwokto2022-06-09?alt=media&token=d5c43615-5c94-4490-9396-5839f6cd24a7',
        },
        {
          name:'La Pyae Win',
          image:'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/Lashio%2Flapyaewunn-ironfabriclpn2022-08-22%2Fprofile%2Flapyaewunn-ironfabriclpn2022-08-22?alt=media&token=b482dad6-b9ac-4150-b167-1da8267468fe',
        },
        {
          name:'Lin La Pyae Wunn',
          image:'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/Lashio%2Flinlapyaewunnlpw2022-07-11%2Fprofile%2Flinlapyaewunnlpw2022-07-11?alt=media&token=234faf26-a7e6-4ee5-8ff8-2dfa6979d46c',
        },
        {
          name:"Me'Time",
          image:'https://firebasestorage.googleapis.com:443/v0/b/flash-mall-ed921.appspot.com/o/Lashio%2Fme\'timemte2022-07-03%2Fprofile%2Fme\'timemte2022-07-03?alt=media&token=59c6e231-4c97-4cbc-b646-c641fe9047d7'
        },
        {
          name:'MSD Cosmetic',
          image:'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/Lashio%2Fmsdcosmeticsmsd2022-06-14%2Fprofile%2Fmsdcosmeticsmsd2022-06-14?alt=media&token=b80004fe-340e-47f3-bf71-f9f037d4f989',
        },
        {
          name:'Ouni Studio',
          image:'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/Lashio%2Founistudioons2022-05-20%2Fprofile%2Founistudioons2022-05-20?alt=media&token=92a511b9-0720-406c-a794-96e87e4e7737'
        },
        {
          name:'Ryda Cloting',
          image:'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/Lashio%2Frydaclothingrdc2022-05-27%2Fprofile%2Frydaclothingrdc2022-05-27?alt=media&token=80163cf5-35f6-41c1-b9cd-4e443daf1af1',
        },
        {
          name:'Ywa Thar Gyi Cafe and Restaurant',
          image:'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/Lashio%2Fywarthargyi2ytg2022-08-26%2Fprofile%2Fywarthargyi2ytg2022-08-26?alt=media&token=6b73db7c-c1e6-4175-ab2a-7964d08b60f4'
        },{
        name:'Shwe Khaing Electronic',
          image:'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/Lashio%2F%E1%80%9B%E1%80%BD%E1%80%BE%E1%80%B1%E1%80%81%E1%80%AD%E1%80%AF%E1%80%84%E1%80%BAskg2022-06-21%2Fprofile%2F%E1%80%9B%E1%80%BD%E1%80%BE%E1%80%B1%E1%80%81%E1%80%AD%E1%80%AF%E1%80%84%E1%80%BAskg2022-06-21?alt=media&token=5bba853b-1d08-4302-abe7-8f52835f16da'
        }
      ]
  }

  }
};
</script>
<style type="text/css" scoped>
.btn-outline-light{
      border-color: var(--primary) !important;

    }
    .btn-outline-light:hover{
      background-color: var(--primary);

    }

    @keyframes animationgradienttitle {
      0% {
        background-position: 0 1600px;
      }
      100% {
        background-position: 1600px 0;
      }
    }

    .my-card{
      cursor: pointer;
      background-color: black;
      margin: 10px;
      width: 50%;
      height: 600px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 1s;
      text-align: center;
      color: var(--light);
    }

    .service-card-body{
      width: 80%;
    }
    .carousel-item img{
      border-radius: var(--border-radius);
    }

    @-webkit-keyframes gradient{
      0% {
        background-position: 0 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
    @keyframes gradient{
      0% {
        background-position: 0 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
.partner-card{
  width: 105%;
  height: 90%;
  margin: 5px ;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--transparent) !important;
  border-radius: var(--border-radius);
}
.partner-card img{
  width: 98%;
  height: 98%;
  border-radius: var(--border-radius);
  transition: 0.4s;
}
.partner-card img:hover{
  transform: scale(1.04);
}
.bounce {
  position: absolute;
  bottom: 30px;
  left: 50% ;
  width: 60px;
  height: 60px ;
  margin-left:-30px;
  -webkit-border-radius:50%;
  -moz-border-radius:50%;
  -ms-border-radius:50%;
  border-radius:50%;
  animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  -moz-animation: bounce 2s infinite;
  -o-animation: bounce 2s infinite;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
  40% {-webkit-transform: translateY(-30px);}
  60% {-webkit-transform: translateY(-15px);}
}

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);}
  40% {-moz-transform: translateY(-30px);}
  60% {-moz-transform: translateY(-15px);}
}

@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-o-transform: translateY(0);}
  40% {-o-transform: translateY(-30px);}
  60% {-o-transform: translateY(-15px);}
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-30px);}
  60% {transform: translateY(-15px);}
}
.img-container{
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
}
.img-one{
  right: 10px;
  width: 100%;
}
.img-two{
  position: absolute;
  top:100px;
  right: 120px;
  width: 30%;
}
.img-three{
  position: absolute;
  right: 20px;
  top:100px;
  width: 30%;
}

.ft-1{
  width: 20%;
  transition: 500ms;
}

.ft-2{
  left: 150px;
  width: 20%;
  transition: 3s;
}

.ft-3{
  left: 300px;
  width: 20%;
  transition: 1.5s;
}
.ft-4{
  left: 350px;
  width: 20%;
  transition: 1.5s;
}

@media screen and (max-width: 1000px){
  .my-card{
    width: 95%;
  }
  .ft-1{
    position: absolute;
    width: 25%;
  }
  .ft-2{
    position: absolute;
    left: 90px;
    width: 25%;
  }
  .ft-3{
    position: absolute;
    left: 180px;
    width: 25%;
  }
  .ft-4{
    left: 260px;
    width: 25%;
    position: absolute;
  }


}

.btn{
  border:2px solid var(--light) !important;
  box-shadow: none !important;
  border-radius: var(--border-radius) !important;
}

.area {
  background: #4e54c8;
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
  width: 100%;
  height: 100vh;
}

.circles{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li{
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;

}

.circles li:nth-child(1){
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2){
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3){
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5){
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6){
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7){
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}



@keyframes animate {

  0%{
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100%{
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
</style>