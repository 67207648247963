<template>
  <div class="container-fluid bg-black">
    <div class="container">
      <div class="row min-vh-100 pt-5 mt-3">
        <div class="col-12 ">
          <h1 class="title-1 mt-3 text-center">Flash Mall EPOS System
          </h1>
          <h2 class="text-center title-2">Available on Web, IOS and Android</h2>
        </div>
        <div class="col-12 col-lg-8 mx-auto">
          <div class="img-container mb-5 pb-5" data-aos="zoom-in">
            <img src="../assets/flash-mockup/flash1.png" data-aos="fade-up-right" style="border-radius: var(--border-radius);" class="img-one" alt="">
            <img src="../assets/flash-mockup/android.png" data-aos="fade-down-left" style="border-radius: var(--border-radius);" class="img-three" alt="">
            <img src="../assets/flash-mockup/ios.png" data-aos="fade-up-left" style="border-radius: var(--border-radius);" class="img-two" alt="">
          </div>
          <p class="fs-5 text-center mb-3 title-2" data-aos="zoom-in">ရောင်းအားတွေတိုးတက်ဖို့ FlashMall နဲ့လက်တွဲစို့။</p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-lg-8 d-flex align-items-start">
          <img src="../assets/flash-mockup/flash1.png" data-aos="zoom-in-up"  class="w-100" alt="">
        </div>
        <div class="col-12 col-lg-4 text-light d-flex align-items-center">
          <div class="text-start" data-aos="fade-right">
            <h1 class="text-start title-2">FlashMall Web</h1>
            <p>If you are familiar with personal computers, you can choose the Flash Mall Web for your shop.</p>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-lg-4 text-light d-flex align-items-center order-1 order-lg-0">
          <div class="text-end" data-aos="fade-left">
            <h1 class="title-2">FlashMall Web</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum et molestias quibusdam recusandae repellat repellendus sunt velit voluptatibus! A aspernatur beatae dicta dolores dolorum esse nulla sequi. Eum ipsum, minima.</p>
          </div>
        </div>
        <div class="col-12 col-lg-8 order-0 order-lg-1">
          <img src="../assets/flash-mockup/flash2.png" data-aos="zoom-in-up" class="w-100" alt="">
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-lg-6 text-center">
          <img src="../assets/flash-mockup/ios1.png" data-aos="fade-left" class="w-50" alt="">
        </div>

        <div class="col-12 col-lg-4 text-light d-flex align-items-center">
          <div class="text-start" data-aos="fade-right">
            <h1 class="text-start title-2">FlashMall IOS</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum et molestias quibusdam recusandae repellat repellendus sunt velit voluptatibus! A aspernatur beatae dicta dolores dolorum esse nulla sequi. Eum ipsum, minima.</p>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-lg-4 mx-auto text-light d-flex align-items-center order-1 order-lg-0">
          <div class="text-end" data-aos="fade-right">
            <h1 class="title-2">FlashMall IOS</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum et molestias quibusdam recusandae repellat repellendus sunt velit voluptatibus! A aspernatur beatae dicta dolores dolorum esse nulla sequi. Eum ipsum, minima.</p>
          </div>
        </div>
        <div class="col-12 col-lg-6 text-center order-0 order-lg-1">
          <img src="../assets/flash-mockup/ios.png" data-aos="fade-left" class="w-50" alt="">
        </div>

      </div>
      <div class="row mt-3">
        <div class="col-12 col-lg-6 text-center">
          <img src="../assets/flash-mockup/android1.png" data-aos="fade-left" class="w-50" alt="">
        </div>

        <div class="col-12 col-lg-4 text-light d-flex align-items-center">
          <div class="text-start" data-aos="fade-right">
            <h1 class="text-start title-2">FlashMall Android</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum et molestias quibusdam recusandae repellat repellendus sunt velit voluptatibus! A aspernatur beatae dicta dolores dolorum esse nulla sequi. Eum ipsum, minima.</p>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-lg-4 mx-auto text-light d-flex align-items-center order-1 order-lg-0">
          <div class="text-end" data-aos="fade-right">
            <h1 class="title-2">FlashMall Android</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum et molestias quibusdam recusandae repellat repellendus sunt velit voluptatibus! A aspernatur beatae dicta dolores dolorum esse nulla sequi. Eum ipsum, minima.</p>
          </div>
        </div>
        <div class="col-12 col-lg-6 text-center order-0 order-lg-1">
          <img src="../assets/flash-mockup/android.png" data-aos="fade-left" class="w-50" alt="">
        </div>

      </div>
      <div class="row mt-3 mx-auto">
        <div class="col-12 col-lg-4 align-items-center">
          <div id="carouselExampleControls" data-aos="zoom-in-up" class="carousel slide" data-bs-ride="carousel" data-interval="500">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/flashmall.jpg" class="d-block carousel-img w-100" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/flashmall2.jpeg" class="d-block carousel-img w-100" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/flashmall3.jpeg" class="d-block carousel-img w-100" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/flashmall4.jpeg" class="d-block carousel-img w-100" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>

        <div class="col-12 col-lg-4">
          <p class="text-light text-start pt-2" data-aos="fade-up" > ဈေးအသက်သာဆုံး EPOS System တစ်ခုကို သင့်လုပ်ငန်းအတွက်အရယူပါ။ ပုံမှန် POS System တစ်ခုထက် အများကြီးသက်သာတဲ့ ဈေးနဲ့ All In One - Smart EPOS System တစ်ခုကို သင်တို့ရဲ့ လုပ်ငန်းတွေအတွက် Light Idea မှ ယူဆောင်လာပါပြီ။<br /> ✅Portable ဖြစ်မယ်။<br /> ✅နေရာလည်းမယူဘူး။<br /> ✅လွယ်ကူသက်သာမယ်။<br /> ✅အမှန်ကန်ဆုံးနဲ့ အတိကျဆုံးသော အချက်အလက်တွေနဲ့ သင့်လုပ်ငန်းကို တိုးတက်စေဖို့ အထောက်အကူပြုမဲ့ 💡Light Idea ရဲ့ FLASH MALL : SMART EPOS SYSTEM <br /> ပေါ့ပေါ့ပါးပါး All In One Smart POS စက်နဲ့လွယ်ကူစွာ အသုံးပြုနိုင်သလို... <br /> ❓Android လား? IOS လား? <br /> ❓Tablet လား? PC လား? <br /> "ကြိုက်သလိုတင်ပြီး စိတ်ကြိုက်သုံး" <br /> ❓Receipt ထုတ်မလား? <br /> ❓Discount တွေပေးမလား? <br /> ❓Stock Control လုပ်မလား? <br /> ❓Promotion coupon တွေ Create လုပ်ဦးမလား? <br /> ဒီလို System တွေအပြင် <br /> ❓ကုန်ပစ္စည်းတစ်ခုချင်းစီအလိုက် Category ခွဲဦးမလား? <br /> ❓Barcode တွေ Generate လုပ်ဦးမလား? <br /> Function အစုံအလင်ပါဝင်ပြီး </p>
        </div>

        <div class="col-12 col-lg-4">
          <p class="text-light text-start" data-aos="fade-up">✅Daily, Monthly, Yearly Sale Report တွေကိုပါ စိတ်ကြိုက်ထုတ်ကြည့်လို့ရတဲ့အထိ Powerful ဖြစ်မဲ့ <br /> 💡Light Idea ရဲ့ FLASH MALL : SMART EPOS SYSTEM <br /> ဒါတွေတင်ပဲလားဆိုတော့... <br /> ✅EPOS ဆိုပေမဲ့ အမြဲတမ်း Internet ရှိနေစရာမလိုဘဲ Internet ပြတ်တောက်သွားလည်း POS System တစ်ခုလိုဆက်သုံးလို့ရနေဦးမယ်။ <br /> ✅Internet ပြန်ရတဲ့အခါ Auto Sync လုပ်လိုက်ရုံနဲ့ တစ်ခဏအတွင်းမှာ တိကျမှန်ကန်တဲ့ Data တွေကို Restore ပြန်လုပ်ပေးသွားဦးမယ်။ <br /> ဒါ့အပြင် လိုအပ်တဲ့ Feature တွေကို စိတ်တိုင်းကျ Customize ထပ်ပြီး ဖြည့်လို့ရဦးမယ်ဆိုတော့... <br /> ဘာလိုသေးလဲဗျာ။ <br /> 💡Online Shop တွေ၊ ကုန်စုံဆိုင်တွေမှမကသေး စားသောက်ဆိုင်လုပ်ငန်းတွေထိပါ အသုံးပြုနိုင်မဲ့ အကောင်းဆုံးနဲ့ အသက်သာဆုံး "FLASH MALL : SMART EPOS SYSTEM" <br /> 🤝m.me/lightideasdc မှာ အခုပဲ အရယူလိုက်ပါ။ <br /> “FLASH MALL : SMART EPOS SYSTEM” <br /> 💡"Lightidea Software Development Company" <br /> ☎️Hotline : 09-798 922 672, 09-402 666 930 <br /> 📨Mail : office@lightideasdc.com </p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.img-container{
  width: 100%;
  position: relative;

}
.img-one{
  right: 10px;
  width: 100%;
}
.img-two{
  position: absolute;
  top:100px;
  right: 120px;
  width: 30%;
}
.img-three{
  position: absolute;
  right: 20px;
  top:50px;
  width: 30%;
}
.carousel-img{
  border-radius: var(--border-radius) !important;
}
</style>