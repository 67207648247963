<template>
  <div id="app">
<!--    <Sidebar/>
    <div style="{'margin-left': sidebarWidth}">

    </div>-->
    <Navigation/>
    <router-view />
    <Footer/>
  </div>
</template>
<script>
import Navigation from "./components/Navigation";
import Footer from "@/components/Footer";
export default {
  components: {Footer, Navigation},
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;700;800&display=swap');
#app {
  font-family: 'Manrope', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
:root{
  --primary: #06f1f9;
  --secondary: #0076bd;
  --transparent: rgba(81, 123, 136, 0.25);
  --discountPrimary: #fa6801;
  --live-bg: #b62323;
  --softPrimary: rgba(179, 184, 248);
  --text-color: black;
  --text-color-light: #434344;
  --btnLight: rgb(184, 189, 248);
  --light: #eef3ff;
  --side-bar-bg: rgb(216, 225, 252);
  --white: #ffffff;
  --gray: #eceff3;
  --dark: #2d2d3b;
  --border-radius:0.5rem;
  --background-color: white;
  --card-color: #ffffff;
}
[data-theme="darkMode"] {
  /* Variables for dark mode */
  --background-color: #0e141e;
  --light: #394067FF;
  --side-bar-bg: #475b9d;
  --text-color: white;
  --text-color-light: #c6c7cb;
  --card-color: #293048D3;
}
.title-1 {
  font-size: 3em;
  font-weight: bolder;
  background: -webkit-linear-gradient(left, var(--primary),var(--secondary) , var(--primary));
  background-size: 1600px 200px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-duration: 5s;
  animation-name: animationgradienttitle;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}
p{
  line-height: 28px;
}
body{
  background-color: black !important;
}
.title-2{
  color: var(--primary);
  font-size: 1.5em;
}
html{
  scroll-behavior: smooth;
}
@-webkit-keyframes gradient{
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient{
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.carousel-control-next{
  background-color: var(--transparent) !important;
  border-radius: 20%;
  width: 20px;
  height: 40px;
  margin: auto;
}
.carousel-control-prev{
  background-color: var(--transparent) !important;
  border-radius: 20%;
  width: 20px;
  height: 40px;
  margin: auto;
}

</style>
