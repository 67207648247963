import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min"
import '@fortawesome/fontawesome-free/js/all'
// importing AOS
import AOS from 'aos'
import 'aos/dist/aos.css'

const app = createApp(App);

app.use(AOS.init({
    duration:3000
}));
// app.component('font-awesome-icon', FontAwesomeIcon);
app.config.productionTip = false;
app.use(store);
app.use(router);
app.mount("#app");
