<template>
  <!--  <LoginModal v-show="loginModal" v-on:close-modal="closeModal"/>-->

  <div>
    <nav class="navbar navbar-expand-lg position-fixed w-100 top-0" style="z-index: 10000">
      <div class="container">

        <router-link class="d-flex text-decoration-none align-items-center" :to="{name:'Home'}">
          <img src="../assets/logoname.svg" width="100">
        </router-link>

        <button class="navbar-toggler border-0" data-bs-toggle="collapse" :class="visible?null:'collapsed'"
                :aria-expanded="visible ? 'true' : 'false'"
                @click="visible = !visible"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-label="Toggle navigation">
          <span > </span>
          <span > </span>
          <span > </span>
        </button>

        <div class="collapse navbar-collapse" :class="visible?null:'collapsed'" id="navbarSupportedContent">

          <ul class="navbar-nav ms-auto mb-2 align-items-center mb-lg-0">

            <li class="nav-item" @click="visible =!visible" >
              <router-link class="nav-link" aria-current="page" :to="{name:'Home'}">Home</router-link>
            </li>
            <li class="nav-item" @click="visible =!visible" >
              <a class="nav-link" aria-current="page" href="/#service">Service</a>
            </li>
            <li class="nav-item" @click="visible =!visible" >
              <a class="nav-link" aria-current="page" href="/#about">About</a>
            </li>
            <li class="nav-item" @click="visible =!visible" >
              <a class="nav-link" aria-current="page" href="/#contact">Contact</a>
            </li>




          </ul>
        </div>
      </div>
    </nav>

  </div>
</template>

<script>
export default {
  name:"Navigation",

  data(){
    return{
      visible:false,
    }
  },

}
</script>
<style>

</style>
<style scoped>
.nav-link{
  color: var(--light);
  font-size: 14px;
}

.navbar{
  background-color: rgba(0, 0, 0, 0.78);
  backdrop-filter: blur(10px);
  color: var(--text-color);
}

li{
  cursor: pointer;
}
.navbar-toggler{
  border:none !important;
  outline: none !important;
  box-shadow: none !important;
  transition: 0.4s;
}
.navbar-toggler:focus{
  transform: scale(1.02);
}
.navbar-toggler{
  border: none;
  outline: none;
  box-shadow: none !important;
}

.navbar-toggler:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.navbar-toggler span {
  display: block;
  background-color: var(--light);
  height: 3px;
  border-radius: 20px;
  width: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  left: 0;
  opacity: 1;
  transition: all 0.35s ease-out;
  transform-origin: center left;
}
/* top line needs a little padding */
.navbar-toggler span:nth-child(1) {
  margin-top: 0.3em;
}
/**
 * Animate collapse into X.
 */
/* top line rotates 45 degrees clockwise and moves up and in a bit to close the center of the X in the center of the button */
.navbar-toggler:not(.collapsed) span:nth-child(1) {
  transform: translate(15%, -33%) rotate(45deg);
}
/* center line goes transparent */
.navbar-toggler:not(.collapsed) span:nth-child(2) {
  opacity: 0;
}
/* bottom line rotates 45 degrees counter clockwise, in, and down a bit to close the center of the X in the center of the button  */
.navbar-toggler:not(.collapsed) span:nth-child(3) {
  transform: translate(15%, 33%) rotate(-45deg) ;
}

/**
 * Animate collapse open into hamburger menu
 */

/* top line moves back to initial position and rotates back to 0 degrees */
.navbar-toggler span:nth-child(1) {
  transform: translate(0%, 0%) rotate(0deg) ;
}
/* middle line goes back to regular color and opacity */
.navbar-toggler span:nth-child(2) {
  opacity: 1;
}
/* bottom line goes back to initial position and rotates back to 0 degrees */
.navbar-toggler span:nth-child(3) {
  transform: translate(0%, 0%) rotate(0deg) ;
}
@media (min-width: 768px) {

}

@media screen and (max-width: 800px) {
  .signin-nav{
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .navbar-nav{
    text-align: center;
  }
  .signin-btn{
    margin: auto;
  }
  .navbar-collapse{
    margin-top: 100px;
    text-align: center;
    height: 100vh;
  }
  .nav-link{
    width: 150px;
    font-size: 18px;
    border-bottom: 1px solid var(--light);
    color: var(--light) !important;
    margin: 20px auto 0 auto;
  }


}
</style>